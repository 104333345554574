@import '../../sass/variables';
@import '../../sass/mixins';

.last-container {
  @include padding-full;
  padding-top: 110px;
  padding-bottom: 110px;

  :first-child {
    background-color: #e7ebfa;

    h2 {
      color: #263238;
    }

    a {
      text-decoration: none;
    }

    button {
      background-color: #141d3a;
      color: #e7ebfa;
    }
  }
}

@media (max-width: 930px) {
  .last-container {
    padding-top: 45px;
    padding-bottom: 45px;
  }
}

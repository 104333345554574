@import '../../../sass/variables';
@import '../../../sass/mixins';

.features-list {
  @include flex-col;
  width: 100%;
}

.features-header {
  // @include flex-row-center;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(6rem, 1fr));
  color: #263238;
  margin-bottom: 5px;

  h4,
  p {
    width: 100%;
    @include flex-row-center;
  }

  h4 {
    font-size: 20px;
  }

  p {
    text-align: center;
    justify-content: center;
  }
}

.features-show {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(6rem, 1fr));

  p,
  div {
    height: 90px;
    @include flex-row-center;
    padding-left: 45px;
    padding-right: 45px;
    width: 100%;
    border: 1px solid #8c8c8c;
    border-right: 0;
    border-bottom: 0;
  }

  div {
    justify-content: center;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  div:last-of-type {
    border-right: 1px solid #8c8c8c;
  }
}

.features-show:nth-last-of-type(2) {
  p,
  div {
    border-bottom: 1px solid #8c8c8c;
  }
}

.features-show.hide-feature:nth-last-of-type(4) {
  // display: none;
  p,
  div {
    opacity: 0.7;
  }
}

.features-show.hide-feature:nth-last-of-type(3) {
  // display: none;
  p,
  div {
    opacity: 0.5;
  }
}

.features-show.hide-feature:nth-last-of-type(2) {
  // display: none;
  p,
  div {
    opacity: 0.3;
    border-bottom: 0;
    border-left: 0;
    position: relative;

    &::before {
      @include linkHover(#8c8c8c, 0);
      top: 0;
      transform: scaleX(1);
      width: 1px;
      height: 60%;
    }
  }

  div:last-of-type {
    border-right: 0;

    position: relative;

    &::after {
      content: '';
      position: absolute;
      background-color: #8c8c8c;
      left: 0;
      top: 0;
      width: 1px;
      height: 60%;
    }

    &::before {
      content: '';
      position: absolute;
      background-color: #8c8c8c;
      left: calc(100% - 1px);
      top: 0;
      width: 1px;
      height: 60%;
    }
  }
}

.see-features {
  @include flex-row-center;
  margin-top: 50px;
  justify-content: center;
  color: #263238;

  p {
    cursor: pointer;
    @include flex-row-center;
    gap: 8px;

    svg {
      width: 14px;
      height: 14px;
    }
  }
}

@media (max-width: 800px) {
  .features-header {
    @include flex-row-center;
    gap: 10px;

    h4 {
      width: 37%;
      font-size: 16px;
    }

    p {
      width: 21%;
      font-size: 13px;
    }
  }

  .features-show {
    @include flex-row-center;
    width: 100%;

    p,
    div {
      padding-left: 16px;
      padding-right: 16px;
      height: 68px;
    }

    p {
      width: 37%;
      font-size: 13px;
      border-left: 0;
      padding-left: 0;
    }

    div {
      width: 21%;

      svg {
        width: 15px;
        height: 15px;
      }
    }

    div:last-of-type {
      border-right: 0;
    }
  }

  .features-show.hide-feature:nth-last-of-type(2) {
    p {
      &::before {
        width: 0;
      }
    }

    div:last-of-type {
      &::before {
        width: 0;
      }
    }
  }
}

@import '../../sass/variables';
@import '../../sass/mixins';

.services-we-offer {
  @include padding-full;
  background-color: #e7ebfa;
  padding-top: 120px;
  padding-bottom: 100px;
  @include flex-col;
  gap: 55px;
  align-items: flex-start;
  color: #263238;

  background-image: url('../../images/services-we-offerobackground.svg');
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;

  h2 {
    font-size: 54px;

    span {
      color: $orange;
    }
  }
}

.services-we-offer-container {
  @include flex-row-center;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  gap: 48px;

  filter: drop-shadow(0px 9px 64px rgba(1, 37, 138, 0.12));
}

.services-we-offer-div {
  @include flex-col;
  background-color: #141d3a;
  border-radius: 13px;
  color: $white;
  min-height: 460px;
  padding: 65px 33px;
  align-items: flex-start;
  gap: 35px;

  p {
    font-size: 14px;
    font-weight: $light-weight;
  }

  a {
    text-decoration: none;
    color: #141d3a;
    width: 100%;
  }

  button {
    width: 100%;
    border-radius: 0;
    background-color: $white;
    color: #141d3a;
  }

  h4 {
    font-size: 20px;
  }

  div {
    @include flex-col;
    align-items: flex-start;
    gap: 12px;
    width: 100%;

    p {
      @include flex-row-center;
      gap: 15px;
      width: max-content;

      svg {
        path {
          fill: $white;
        }
      }
    }
  }
}

@media (max-width: 1085px) {
  .services-we-offer-container {
    gap: 25px;
  }
}

@media (max-width: 980px) {
  .services-we-offer {
    align-items: center;
    background-image: url('../../images/services-we-offerobackground-mobile.svg');

    h2 {
      width: 90%;
      text-align: center;
      font-size: 38px;
    }
  }

  .services-we-offer-container {
    width: 80%;
    flex-direction: column;
    align-items: center;
  }

  .services-we-offer-div {
    width: 100%;
  }
}

@media (max-width: 550px) {
  .services-we-offer {
    padding: 35px 40px 65px;

    h2 {
      font-size: 28px;
    }
  }

  .services-we-offer-container {
    width: 100%;
    min-height: 100%;
  }

  .services-we-offer-div {
    h4 {
      font-size: 18px;
    }

    div {
      p {
        font-size: 12px;
      }
    }
  }
}

.reusable-button {
  cursor: pointer;
  border: 1px solid;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  min-height: 40px;

  display: flex;
  align-items: center;
  gap: 10px;
}

.spinner {
  animation: rotate 0.7s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

$dark-blue: #141d3a;
$text-light: #e7ebfa;
$light-gray: #909090;
$white: #ffffff;
$nav-color: #727272;
$black: #101010;
$orange: #d75023;

$semi-bold-weight: 600;
$light-weight: 300;
$regular-weight: 400;
$medium-weight: 500;

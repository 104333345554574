@import '../../sass/variables';
@import '../../sass/mixins';

.aboutus {
  @include padding-full;
  @include flex-col;
  padding-top: 100px;
  padding-bottom: 150px;
  background-color: #1d2643;
  color: $white;
  gap: 95px;
  position: relative;

  h3 {
    font-size: 40px;
  }

  p {
    font-size: 16px;
  }

  &::before {
    content: '';
    position: absolute;
    background-color: $white;
    left: 0;
    bottom: calc(100% + 4px);
    width: 100%;
    height: 1px;
  }
}

.aboutus-header {
  h2 {
    font-size: 48px;
    font-weight: $medium-weight;
  }
}

.aboutus-content {
  display: flex;
  gap: 70px;

  aside {
    @include flex-col;
    gap: 40px;
    width: 50%;
    height: 100%;

    h3 {
      font-weight: $medium-weight;
    }

    p {
      line-height: 2;
    }
  }

  img {
    object-fit: fill;
    width: calc(50% - 70px);
    max-height: 20rem;
  }
}

.aboutus-contact {
  @include flex-col;
  gap: 32px;

  h3 {
    margin-bottom: 20px;
  }

  p {
    @include flex-row-center;
    gap: 24px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

@media (max-width: 1072px) {
  .aboutus-content {
    img {
      max-height: 25rem;
    }
  }
}

@media (max-width: 840px) {
  .aboutus-content {
    flex-direction: column-reverse;
    align-items: center;

    aside {
      width: 100%;
    }

    img {
      max-height: unset;
      width: 80%;
    }
  }
}

@media (max-width: 500px) {
  .aboutus {
    padding-top: 50px;
    padding-bottom: 50px;
    gap: 60px;

    h3 {
      font-size: 32px;
    }
  }

  .aboutus-header {
    h2 {
      font-size: 40px;
    }
  }

  .aboutus-content {
    img {
      width: 90%;
    }
  }

  .aboutus-contact {
    h3 {
      margin-bottom: 15px;
    }

    gap: 15px;
  }
}

@import '../../sass/variables';
@import '../../sass/mixins';

.navbar {
  @include padding-full;
  min-height: 122px;
  @include flex-row-center;
  justify-content: space-between;
  position: relative;

  &::before {
    @include linkHover(rgba($color: #000000, $alpha: 0.53), 4px);
    width: calc(100% - 240px);
    height: 1px;
    left: 120px;
    transform: scaleX(1);

    @media (max-width: 1120px) {
      width: calc(100% - 120px);
      left: 60px;
    }

    @media (max-width: 600px) {
      width: calc(100% - 32px);
      left: 16px;
    }
  }
}

.navbar.fixed {
  background-color: $white;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  // z-index: 40000000000000;

  &::before {
    bottom: 0;
  }
}

.navbar-logo {
  @include flex-row-center;
  // width: 220px;
  // height: 55px;
  position: relative;
  overflow: hidden;
}

.nav-list {
  @include flex-row-center;
  list-style-type: none;
  gap: 60px;
  justify-content: space-between;
  width: 67%;

  div {
    @include flex-row-center;
    gap: 60px;
  }

  li {
    text-decoration: none;

    a {
      color: $nav-color;
      font-weight: $regular-weight;
      font-size: 14px;
      text-decoration: none;
      position: relative;

      &::before {
        @include linkHover($orange, -4px);
      }

      &:hover::before {
        transform: scaleX(1);
      }
    }

    .nav-link-active {
      color: $black;

      &::before {
        transform: scaleX(1);
      }
    }
  }
}

.button-link {
  text-decoration: none;
}

.nav-button {
  font-weight: $regular-weight;
  background-color: #103acc;
  border: none;
  color: $white;
}

.menu-bar {
  display: none;
  cursor: pointer;
}

@media (max-width: 900px) {
  .navbar {
    position: relative;
  }

  .menu-bar {
    position: relative;
    @include flex-col;
    height: 20px;
    gap: 5px;
    width: 30px;

    span {
      @include transition(transform);
      background-color: #000000;
      height: 2px;
      width: 30px;
    }
  }

  .menu-bar.active {
    span:nth-of-type(1) {
      position: absolute;
      bottom: 50%;
      left: 0;
      transform: rotate(45deg);
    }

    span:nth-of-type(2) {
      display: none;
    }

    span:nth-of-type(3) {
      position: absolute;
      bottom: 50%;
      right: 0;
      transform: rotate(135deg);
    }
  }

  .nav-list {
    // display: none;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    transform: scaleY(0);
    transform-origin: top;
    @include transition(transform);

    position: absolute;
    left: 0;
    top: calc(100% - 5px);

    background-color: #141d3a;
    padding: 20px 50px 45px;
    width: 100%;

    div {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      gap: 24px;
    }

    li {
      a {
        color: $white;
      }

      .nav-link-active {
        color: $white;
      }
    }
  }

  .nav-list.active {
    transform: scaleY(1);
    z-index: 50;
    // display: flex;
  }

  .button-link {
    width: 100%;

    button {
      background-color: $white;
      border-radius: 17px;
      height: 40px;
      color: #141d3a;
      width: 100%;

      &::after {
        content: url(../../images/get-started-mobile-icon.svg);
        position: relative;
        top: 1px;
      }
    }
  }
}

@media (max-width: 500px) {
  .navbar-logo {
    width: 100%;

    img {
      width: 75%;
      height: 100%;
    }
  }
}

@import '../../../sass/variables';
@import '../../../sass/mixins';

.demo-form {
  @include flex-col;
  padding: 54px 32px 82px;
  background-color: $white;
  color: #263238;
  gap: 25px;

  label {
    font-weight: 500;
  }

  p {
    font-weight: 300;
  }

  button {
    border: none;
    border-radius: 0;
    width: 100%;
    color: $white;
    background-color: #d75023;
    margin-top: 10px;
  }
}

.text-input-div {
  display: flex;
  gap: 16px;

  aside {
    width: 50%;
    @include flex-col;
    gap: 8px;

    label {
      font-size: 14px;
    }

    span {
      font-size: 12px;
      color: #d75023;
    }

    input {
      width: 100%;
      height: 2.5rem;
      border: 0.5px solid rgba($color: #000000, $alpha: 0.5);
      background-color: #f2f4fb;
      padding-left: 15px;

      &:focus {
        outline: none;
      }
    }
  }
}

.checkbox-div {
  @include flex-row-center;
  gap: 16px;
  margin-top: 10px;
  margin-bottom: 10px;

  input {
    appearance: none;
    cursor: pointer;
    height: 18px;
    width: 18px;
    background-color: #f2f4fb;
    border: 0.5px solid rgba($color: #000000, $alpha: 0.5);
    @include transition(background-color background-image);

    &:checked {
      background-color: #d75023;
      background-image: url(../../../images/checked-white.svg);
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  p {
    font-weight: 500;
  }
}

@media (max-width: 500px) {
  .text-input-div {
    flex-direction: column;

    aside {
      width: 100%;
    }
  }

  .checkbox-div {
    p {
      font-size: 14px;
    }
  }
}

@import '../../sass/variables';
@import '../../sass/mixins';

.partner-us {
  @include padding-full;
  padding-top: 50px;
  padding-bottom: 50px;
  @include flex-col;
  gap: 80px;

  h2 {
    font-size: 38px;
    font-weight: $medium-weight;
    color: #263238;

    span {
      color: $orange;
    }
  }
}

.card-list {
  @include flex-row-center;
  align-items: flex-start;
  gap: 50px;
}

.card-show {
  @include flex-col;
  gap: 10px;
  align-items: center;
  box-shadow: 0px 19px 33px rgba(230, 230, 230, 0.25);
  border-radius: 16px;
  text-align: center;
  padding: 40px;

  div {
    @include circleDiv(#f9fbff, 80px);
    margin-bottom: 15px;
  }

  h5 {
    font-size: 16px;
    font-weight: $medium-weight;
    color: #263238;
  }

  p {
    font-size: small;
    color: rgba($color: #000000, $alpha: 0.7);
  }
}

.card-show:nth-of-type(2) {
  margin-top: 30px;
}

@media (max-width: 930px) {
  .partner-us {
    align-items: center;
    text-align: center;
    gap: 60px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .card-list {
    flex-direction: column;
    align-items: center;
  }

  .card-show {
    width: 100%;
  }

  .card-show:nth-of-type(2) {
    margin-top: 0;
  }
}

@media (max-width: 690px) {
  .partner-us {
    h2 {
      font-size: 24px;
    }
  }

  .card-show {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .card-list {
    gap: 25px;
  }

  .card-show {
    width: 100%;
  }
}

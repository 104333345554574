@import '../../../sass/variables';
@import '../../../sass/mixins';

.mini-marketing-plan {
  @include padding-full;
  padding-top: 98px;
  padding-bottom: 98px;
}

.marketing-form {
  width: 100%;
  height: 100%;
  padding: 70px 88px 86px;
  @include flex-col;
  gap: 60px;

  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 27px rgba($color: rgba(0, 0, 0, 0.62), $alpha: 0.1);

  h2 {
    margin-bottom: 20px;
    text-align: center;
    color: #103acc;
  }
}

.details-container {
  @include flex-col;
  gap: 30px;

  div {
    @include flex-row-center;
    gap: 30px;
    align-items: flex-start;

    aside {
      @include flex-col;
      width: 50%;

      span {
        font-size: 10px;
        color: $orange;
      }
    }

    input {
      border: 1px solid rgba(152, 152, 152, 0.3);
      border-radius: 4px;
      padding-left: 20px;
      width: 100%;
      min-height: 2.5rem;

      &::placeholder {
        color: rgba(0, 0, 0, 0.4);
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.checkbox-container {
  @include flex-col;
  gap: 15px;

  p:first-child {
    font-weight: $medium-weight;
    color: #103acc;
  }

  div {
    @include flex-col;
    gap: 15px;

    span {
      @include flex-row-center;
      gap: 10px;
      font-weight: $regular-weight;
      font-size: small;

      input {
        appearance: none;
        width: 17px;
        height: 17px;
        border: 1px solid rgba($color: #98989880, $alpha: 0.5);
        border-radius: 2px;

        &:checked {
          cursor: pointer;
          background-image: url(../../../images/checked.svg);
          background-position: center;
          background-repeat: no-repeat;
        }
      }

      p {
        color: #263238;
      }
    }
  }
}

.textarea-container {
  @include flex-col;
  gap: 15px;

  p {
    color: #103acc;
    font-weight: $medium-weight;
  }

  textarea {
    padding: 15px;
    min-height: 130px;
    border-radius: 4px;
    border: 1px solid rgba($color: #9898984d, $alpha: 0.3);

    &:focus {
      outline: none;
    }
  }
}

button {
  justify-content: center;
  background-color: #103acc;
  color: $white;
}

@media (max-width: 768px) {
  .details-container {
    div {
      flex-direction: column;

      aside {
        width: 100%;
      }

      input {
        width: 100%;
      }
    }
  }
}

@media (max-width: 500px) {
  .marketing-form {
    padding: 62px 15px 81px;
    gap: 40px;
  }
}

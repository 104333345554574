@import '../../../sass/variables';
@import '../../../sass/mixins';

.demo-content {
  @include padding-full;
  display: flex;
  align-items: flex-start;
  gap: 60px;
  padding-top: 145px;
  padding-bottom: 215px;
  background-color: #1d2643;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background-color: $white;
    left: 0;
    bottom: calc(100% + 4px);
    width: 100%;
    height: 1px;
  }

  aside {
    width: 50%;
  }
}

.demo-content-left {
  @include flex-col;
  gap: 50px;
  color: $white;

  h2 {
    font-size: 64px;
    font-weight: 600;
  }

  p {
    font-size: 16px;
    font-weight: 400px;
    line-height: 1.5;
  }

  h4 {
    font-size: 24px;
    font-weight: 600;
  }

  h5 {
    font-size: 22px;
    font-weight: 600;
  }
}

.features-div {
  display: flex;
  gap: 140px;

  div {
    @include flex-col;
    gap: 22px;

    p {
      @include flex-row-center;
      gap: 14px;

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}

@media (max-width: 1050px) {
  .demo-content-left {
    h2 {
      font-size: 48px;
    }
  }
}

@media (max-width: 1000px) {
  .features-div {
    gap: 80px;
  }
}

@media (max-width: 980px) {
  .demo-content {
    flex-direction: column;

    aside {
      width: 100%;
    }
  }

  .features-div {
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: flex-end;
  }
}

@media (max-width: 650px) {
  .demo-content {
    padding-top: 60px;
    padding-bottom: 70px;
  }

  .demo-content-left {
    gap: 50px;

    h2 {
      font-size: 32px;
    }
  }
}

@import '../../../sass/variables';
@import '../../../sass/mixins';

.hero-strategy {
  @include padding-full;
  padding-top: 120px;
  padding-bottom: 200px;

  @include flex-row-center;
  gap: 90px;

  background-image: url('../../../images/hero-strategy-background.svg');
  background-repeat: no-repeat;
  background-position: center;
}

// HERO STRATEGY LEFT
.hero-strategy-left {
  @include flex-col;
  width: 50%;
  gap: 30px;
  color: #414141;

  h2 {
    font-size: 58px;

    span {
      color: $orange;
    }
  }

  button {
    background-color: #103acc;
    border: none;
    width: max-content;
    color: $white;

    div {
      @include circleDiv(#e7ebfa, 27px);
      animation: movesFoward 0.6s infinite forwards;

      svg {
        color: #103acc;
      }
    }
  }
}

@keyframes movesFoward {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(20%);
  }
}

@keyframes movesDown {
  0% {
    transform: translateY(-5%);
  }

  100% {
    transform: translateY(5%);
  }
}

// HERO STRATEGY RIGHT
.hero-strategy-right {
  width: 50%;
}

@media (max-width: 1055px) {
  .hero-strategy {
    flex-direction: column;
  }

  .hero-strategy-left {
    width: 100%;
    align-items: center;
    text-align: center;

    button {
      div {
        animation: movesDown 0.6s infinite forwards;

        svg {
          transform: rotate(90deg);
        }
      }
    }
  }

  .hero-strategy-right {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .hero-strategy-right {
    width: 90%;
  }
}

@media (max-width: 650px) {
  .hero-strategy {
    padding-top: 80px;
    padding-bottom: 130px;
  }

  .hero-strategy-left {
    h2 {
      font-size: 32px;
    }
  }
}

@media (max-width: 535px) {
  .hero-strategy-right {
    width: 100%;
  }
}

@import './variables';
@import './mixins';
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Lexend', sans-serif;
}

.app {
  background-color: #ffffff;
}

@import '../../../sass/variables';
@import '../../../sass/mixins';

.schedule-success {
  @include flex-col;
  align-items: center;
  background-color: $white;
  box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.2);
  line-height: 2;
  text-align: center;
  padding: 50px 22px;

  h3 {
    font-size: 20px;
    color: #5d5e61;
  }

  p {
    color: rgba($color: #5d5e61, $alpha: 0.9);
  }

  h4 {
    font-size: 20px;
    color: #263238;
  }

  button {
    background-color: #e7ebfa;
    border-radius: 0;
    border: 0.5px solid #797a7e;
    color: #797a7e;
  }
}

@media (max-width: 768px) {
  .schedule-success {
    width: 100%;
  }
}

@mixin flex-col {
  display: flex;
  flex-direction: column;
}

@mixin flex-row-center {
  display: flex;
  align-items: center;
}

@mixin padding-full {
  padding-left: 120px;
  padding-right: 120px;

  @media (max-width: 1120px) {
    padding-left: 60px;
    padding-right: 60px;
  }

  @media (max-width: 600px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@mixin linkHover($backgroundColor, $bottom) {
  content: '';
  position: absolute;
  left: 0;
  bottom: $bottom;
  background-color: $backgroundColor;
  width: 100%;
  height: 2px;

  transform-origin: left;
  transform: scaleX(0);
  transition: transform 0.5s ease-in-out;
}

@mixin gridBar($backgroundColor) {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 9px;
  background-color: $backgroundColor;
}

@mixin circleDiv($backroundColor, $width) {
  @include flex-row-center;
  justify-content: center;
  background-color: $backroundColor;
  width: $width;
  height: $width;
  border-radius: 50%;
}

@mixin transition($property) {
  transition-property: $property;
  transition: 0.3s ease-in-out;
}

@import '../../sass/variables';
@import '../../sass/mixins';

.last-section {
  @include padding-full;
  padding-top: 110px;
  padding-bottom: 140px;
  background-color: $white;
  @include flex-col;
  gap: 120px;
}

.last-section-one {
  @include flex-row-center;
  gap: 90px;
  // display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  // column-gap: 120px;
  // row-gap: 60px;
}

.last-section-one-div:first-of-type {
  @include flex-col;
  width: 58%;
  justify-content: center;
  gap: 30px;

  h2 {
    font-size: 38px;
    color: #263238;

    span {
      color: $orange;
    }
  }

  p {
    color: #414141;
  }

  a {
    width: max-content;
    position: relative;
    text-decoration: none;
    color: #011f8a;

    &::before {
      @include linkHover(#011f8a, -4px);
      transform: scaleX(1);
    }
  }
}

.last-section-one-div:last-of-type {
  @include flex-row-center;
  justify-content: flex-end;
  width: 42%;
  position: relative;

  img {
    object-fit: fill;
    border-radius: 65px 0;
    width: 100%;
    height: 490px;
  }

  div {
    position: absolute;
    padding: 12px 30px;
    border-radius: 95px;
    color: $white;
    font-size: 24px;
    background: linear-gradient(
      95.97deg,
      rgba(239, 239, 239, 0.6) 5.99%,
      rgba(239, 239, 239, 0.1) 89.63%
    );
    backdrop-filter: blur(25px);
  }

  .creativity {
    top: 10%;
    left: 15%;
  }

  .strategy {
    right: 2%;
    top: 50%;
  }

  .results {
    left: 5%;
    top: 85%;
  }
}

@media (max-width: 1060px) {
  .last-section-one {
    gap: 40px;
  }
}

@media (max-width: 900px) {
  .last-section-one {
    flex-direction: column-reverse;
    gap: 60px;
  }

  .last-section-one-div:first-of-type {
    width: 100%;
    align-items: center;
    text-align: center;

    h2 {
      font-size: 24px;
    }
  }

  .last-section-one-div:last-of-type {
    width: 70%;

    img {
      max-height: 420px;
    }

    div {
      font-size: 14px;
      padding: 7px 10px;
      border-radius: 55px;
    }
  }
}

@media (max-width: 1110px) {
  .last-section-one-div:last-of-type {
    div {
      font-size: 14px;
      border-radius: 55px;
    }
  }
}

@media (max-width: 816px) {
  // .last-section-one-div:first-of-type {
  //   // grid-row: 2;
  // }

  .last-section-one-div:last-of-type {
    display: flex;
    justify-content: center;

    div {
      padding: 7px 10px;
    }

    img {
      width: 100%;
    }

    .creativity {
      left: 20%;
    }

    .strategy {
      right: 12%;
    }

    .results {
      left: 15%;
    }
  }
}

@media (max-width: 500px) {
  .last-section {
    padding-top: 60px;
    padding-bottom: 60px;
    gap: 70px;
  }

  .last-section-one-div:last-of-type {
    width: 80%;
  }
}

@media (max-width: 370px) {
  .last-section-one {
    @include flex-col;
    flex-direction: column-reverse;
    gap: 60px;
  }
}

// LAST SECTION TWO
.last-section-two {
  padding: 100px 150px;
  background-color: #e7ebfa;

  @include flex-col;
  gap: 60px;
  align-items: flex-start;

  h2 {
    font-size: 38px;
    color: #263238;

    span {
      color: $orange;
    }
  }

  a {
    text-decoration: none;

    button {
      border: none;
      color: $white;
      background-color: #141d3a;
    }
  }
}

@media (max-width: 990px) {
  .last-section-two {
    padding: 100px 10%;
  }
}

@media (max-width: 640px) {
  .last-section-two {
    padding: 80px 10%;

    h2 {
      font-size: 24px;
    }
  }
}

@import '../sass/variables';
@import '../sass/mixins';

.modal-container {
  position: fixed;
  right: 10%;
  top: 20%;
  width: 40%;
}

.modal {
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100px;
  background-color: $white;
  border-radius: 5px;

  z-index: 15;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  padding-left: 30px;
}

.left-bar {
  width: 10px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: $orange;
  border-radius: 5px 0 0 5px;
}

.left-bar.success {
  background-color: #141d3a;
}

.modal-content {
  @include flex-row-center;
  justify-content: space-between;
  height: 100%;
  font-size: 14px;

  svg {
    font-size: 34px;
  }

  svg.success {
    color: #141d3a;
  }

  svg.failed {
    color: $orange;
  }

  div:first-child {
    @include flex-row-center;
    gap: 15px;
  }
}

.modal-message {
  @include flex-col;
  align-items: flex-start;
  color: #8c8c8c;

  p:first-of-type {
    font-weight: $semi-bold-weight;
    font-size: larger;
  }
}

.close-div {
  cursor: pointer;
  height: 100%;
  padding: 0 30px;
  @include flex-row-center;
  border-left: #8c8c8c 1px solid;
  color: #8c8c8c;
}

@media (max-width: 1080px) {
  .modal-container {
    width: 50%;
  }
}

@media (max-width: 800px) {
  .modal-container {
    width: 70%;
  }
}

@media (max-width: 560px) {
  .modal-container {
    width: 80%;
    right: 10%;
  }
}
